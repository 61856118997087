<template>
  <v-app-bar class="appBar" dense app elevation="0">
    <v-app-bar-nav-icon class="theme--light" @click="toggleDrawer()">
      <v-icon>mdi-menu</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title>Kitchen</v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- <v-btn class="mr-2 theme--light" icon>
      <v-icon style="font-size: 40px" @click="toggleFloor()">
        mdi-autorenew
      </v-icon>
    </v-btn>
    <v-btn class="mr-0 theme--light" icon>
      <v-icon style="font-size: 40px" @click="zoomOut()">
        mdi-magnify-minus-outline
      </v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
export default {
  name: "BuildingsAppBar",
  data: function () {
    return {
      param: 0,
    };
  },
  methods: {
    toggleDrawer() {
      this.$store.commit("toggleDrawer", ["kitchen", true]);
    },
    toggleFloor() {
      this.$store.commit("toggleFloor");
    },
    zoomOut() {
      this.$store.commit("zoomOut");
    },
  },
};
</script>
